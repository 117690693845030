<div class="main-container" *ngIf="rootViewModel">
    <nts-expandable *ngFor="let panel of layoutMetaData.panels; let i = index"
      [panels]="rootViewModel.panels"
      [panelId]="panel.panelId" [isCollapsed]="panel.panelState == panelState.Collapsed"
      [isVisible]="panel.isVisible"
      [title]="panel.descriptions.displayName" [showAdditionalFields]="panel.panelState == panelState.Expanded"
      [hideAdditionalFieldsToggle]="panel.additionalFields.length == 0"
      [promotedFields]="getPromotedFields(panel, i)">
        <ng-template ngFor let-field [ngForOf]="panel.sortedFields">
            <nts-spacer-box #expandableChild *ngIf="field.fieldType === fieldType.Spacer" [isHidden]="!field.isVisible"
                [additionalField]="field.isAdditionalField" [fullColumn]="field.isFullColumn">
            </nts-spacer-box>
            <nts-labelled-text-box #expandableChild *ngIf="field.fieldType === fieldType.Simple"
                [isHidden]="!field.isVisible" [additionalField]="field.isAdditionalField"
                [fullColumn]="field.isFullColumn"
                [isDisabled]="field.isDisabled"
                [multiline]="getSimpleFieldMetaDataFromFieldMetaData(field).isMultiLine"
                [propertyViewModel]="getPropertyViewModelFromFieldMetaData(field)">
            </nts-labelled-text-box>
            <nts-grid #expandableChild *ngIf="field.fieldType === fieldType.Grid" [isHidden]="!field.isVisible"
                [areAllCommandsDisabled]="getGridMetaDataFromFieldMetaData(field).areAllCommandsDisabled"
                [isDisabled]="getGridMetaDataFromFieldMetaData(field).areAllColumnsDisabled"
                [title]="field.description"
                [showToolbar]="getGridMetaDataFromFieldMetaData(field).showToolbar"
                [additionalField]="field.isAdditionalField" [fullColumn]="field.isFullColumn"
                [minRowNumberHeight]="getGridMetaDataFromFieldMetaData(field).minRowNumberHeight"
                [dynamicHeight]="getGridMetaDataFromFieldMetaData(field).dynamicHeight"
                [collectionViewModel]="getCollectionViewModelFromGridFieldMetaData(getGridMetaDataFromFieldMetaData(field))"
                [columns]="getCollectionViewModelColumnsFromGridFieldMetaData(getGridMetaDataFromFieldMetaData(field))">
            </nts-grid>
            <nts-decode-text-box #expandableChild *ngIf="field.fieldType === fieldType.External"
                [isHidden]="!field.isVisible" [additionalField]="field.isAdditionalField"
                [fullColumn]="field.isFullColumn"
                [isDisabled]="field.isDisabled"
                [externalPropertyViewModel]="getExternalPropertyViewModelFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))"
                [fieldSeparator]="getExternalMetaDataFromFieldMetaData(field).fieldSeparator"
                [searchProperties]="getExternalMetaDataFromFieldMetaData(field).searchProperties"
                [decodeProperties]="getExternalMetaDataFromFieldMetaData(field).decodeProperties">
            </nts-decode-text-box>
            <nts-external-list #expandableChild
                *ngIf="field.fieldType === fieldType.ExternalList"
                [isHidden]="!field.isVisible"
                [additionalField]="field.isAdditionalField"
                [fullColumn]="field.isFullColumn"
                [isDisabled]="field.isDisabled"
                [externalPropertyViewModel]="getExternalPropertyViewModelFromExternalMetaData(getExternalMetaDataFromFieldMetaData(field))"
                [fieldSeparator]="getExternalMetaDataFromFieldMetaData(field).fieldSeparator"
                [searchProperties]="getExternalMetaDataFromFieldMetaData(field).searchProperties"
                [decodeProperties]="getExternalMetaDataFromFieldMetaData(field).decodeProperties">
            </nts-external-list>
            <nts-custom-field-container #expandableChild *ngIf="field.fieldType === fieldType.Custom"
                [isHidden]="!field.isVisible" [additionalField]="field.isAdditionalField"
                [isDisabled]="field.isDisabled"
                [fullColumn]="field.isFullColumn" [metaData]="getCustomFieldMetaDataFromFieldMetaData(field)"
                [ovm]="orchestratorViewModel">
            </nts-custom-field-container>
        </ng-template>
    </nts-expandable>
</div>
